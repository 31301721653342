/**
* Detect IOS safari
* @returns {Array|{index: number, input: string}}
*/
function isMobileSafari() {
    var ua = window.navigator.userAgent;
    return /(iPad|iPhone|iPod).*WebKit/.test(ua) && !/(CriOS|OPiOS)/.test(ua);
}

if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {

    var x = document.getElementsByClassName('caption');
    var i;
    for (i = 0; i < x.length; i++) {
        x[i].className += ' caption__mobile';
    }
}

if (isMobileSafari()) {
    document.body.classList.add('safari-ios');
}

/**
 * Lazy load images
 *
 * https://github.com/ApoorvSaxena/lozad.js
 */
document.addEventListener('DOMContentLoaded', function() {
    lozad('.lozad', {
        threshold: 0.1
    }).observe();
});
