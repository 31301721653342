/**
 * VARIABLES
 *
 * @type {HTMLElement | null}
 */
var site = document.getElementById('siteContent');
var header = document.getElementById('header');
var breadcrumbs = document.getElementById('breadcrumbs');
var menu = document.getElementById('mainMenu');
var menuOpen = document.getElementById('menuOpen');
var menuClose = document.getElementById('menuClose');
var hamburger = document.getElementsByClassName('navbar__hamburger')[0];
var subMenu = document.getElementsByClassName('submenu');
var subMenuClose = document.getElementsByClassName('submenu__close');
var subMenuToggle = document.getElementsByClassName('menu__link_submenu');
var root = document.getElementsByTagName( 'html' )[0];
var logoHome = document.getElementsByClassName('navbar__logo')[0];

/**
 * OPEN CLOSE MENU
 */
menuOpen.onclick = function() { openMenu() };
menuClose.onclick = function() { closeMenu() };


// function openMenu() {
//     menu.classList.add('menu_open');
//     hamburger.classList.add('navbar__hamburger_open');
//     document.body.classList.add('menu-open');
//     root.classList.add('menu-open');
//
//     setTimeout(function() {
//         site.classList.add('Blur(5px)');
//         breadcrumbs.classList.add('Blur(5px)');
//     }, 500);
//
//     if (window.matchMedia("(max-width: 767px)").matches) {
//         if (logoHome.classList.contains('navbar__logo_theme_homepage')) {
//             logoHome.classList.add('navbar__logo_theme_menu-open');
//         }
//     } else {
//
//         setTimeout(function() {
//             header.classList.add('Blur(5px)');
//         }, 500);
//
//     }
// }

function openMenu() {
    menu.classList.toggle('menu_open');
    hamburger.classList.toggle('navbar__hamburger_open');
    document.body.classList.toggle('menu-open');
    root.classList.toggle('menu-open');

    if (window.matchMedia("(max-width: 767px)").matches) {
        if (logoHome.classList.contains('navbar__logo_theme_homepage')) {
            logoHome.classList.toggle('navbar__logo_theme_menu-open');
        }
    }
}

function closeMenu() {
    menu.classList.remove('menu_open');
    document.body.classList.remove('menu-open');
    root.classList.remove('menu-open');

    if (window.matchMedia("(max-width: 767px)").matches) {
        if (logoHome.classList.contains('navbar__logo_theme_homepage')) {
            logoHome.classList.remove('navbar__logo_theme_menu-open');
        }
    }
}

/**
 * OPEN CLOSE SUBMENU
 */

// subMenuToggle.addEventListener('focusout', closeSubMenu);

function toggleSubMenu(element) {
    var category = element.target.dataset.type;

    closeSubMenu();

    document.getElementById('submenu-' + category).classList.add('submenu_active');
}

function closeSubMenu() {
    for(var i = 0; i < subMenu.length; i++) {
        subMenu[i].classList.remove('submenu_active');
    }
}

for(var i = 0; i < subMenuToggle.length; i++) {
    subMenuToggle[i].addEventListener('click', function(e) {
        toggleSubMenu(e);
    });
}

for(var i = 0; i < subMenuClose.length; i++) {
    subMenuClose[i].addEventListener('click', function() {
        closeSubMenu();
    });
}
